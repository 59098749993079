.wrapper {
    background-color: rgb(250, 240, 253);
    overflow: auto;
    padding-left: 10px;
    padding-right: 10px;
}
.input {
    width: 80%;
    float: right;
    margin-left: 10px;
    background-color: rgb(232, 225, 235);
    border: none;
    padding: 2px;
}
.input:focus {
    outline: none;
}
.group {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
}
.left {
    width: 70%;
    float: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    font-weight: bold;
}
.right {
    width: 30%;
    float: right;
    margin-top: 24px;
    margin-bottom: 25px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
}
.button {
    width: 100%;
    font-size: 18px;
    border: none;
    background-color: rgb(179, 38, 224);
    color: white;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}
.button:hover {
    background-color: rgb(141, 29, 179);
}
.button:focus {
    outline: none;
}
.buttonInactive {
    width: 100%;
    font-size: 18px;
    border: none;
    background-color: rgb(210, 183, 219);
    color: white;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
}
