.wrapper {
}
.table {
    border: 2px solid black;
    width: 100%;
}
.tr {
    border: 2px solid black;
}
.yes {
    border: 2px solid black;
    background-color: rgb(18, 173, 47);
    width: 20%;
    text-align: center;
    color: white;
    padding: 3px;
}
.no {
    border: 2px solid black;
    background-color: rgb(199, 20, 20);
    width: 20%;
    text-align: center;
    color: white;
    padding: 3px;
}
.right {
    border: 2px solid black;
    width: 80%;
    padding: 3px;
    padding-left: 5px;
}
