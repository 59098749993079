@font-face {
    src: url("./components/styles/fonts/meta-normal.ttf");
    font-family: "Meta";
    font-style: normal;
    font-weight: normal;
}

body {
    margin: 0;
    font-family: "Meta", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
