.wrapper {
    overflow: auto;
    font-size: 18px;
}
.label {
    width: 100%;
}
.labels {
    color: rgb(179, 38, 224);
    width: 10%;
    float: left;
    text-align: right;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px;
    font-weight: bold;
}
.texts {
    width: 90%;
    float: right;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    font-weight: bold;
}
.url {
    width: 100%;
    color: rgb(60, 38, 225);
}
.text {
    width: 100%;
}
.errorText {
    width: 100%;
    color: red;
}
.current {
    float: left;
    width: 66%;
}
.buttons {
    float: right;
    width: 34%;
    text-align: center;
    font-size: 16px;
}
.button {
    color: white;
    background-color: rgb(179, 38, 224);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 45px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: bold;
    border: none;
}
.buttonInactive {
    color: white;
    background-color: rgb(210, 183, 219);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 45px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: bold;
    border: none;
}
.button:focus {
    outline: none;
}
.buttonInactive:focus {
    outline: none;
}
.button:hover {
    background-color: rgb(141, 29, 179);
}
.a {
    color: rgb(60, 38, 225);
    text-decoration: none;
}
.a:hover {
    color: rgb(60, 38, 225);
}
.loader {
    width: 100%;
    display: inline-flex;
    overflow: hidden;
}
