.input {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}
.current {
    margin-top: 20px;
    margin-bottom: 10px;
}
