.wrapper {
    background-color: rgb(179, 38, 224);
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 17px;
}
.authentication {
    float: right;
}
