.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(179, 38, 224);
    width: 50px;
    height: 50px;
    align-self: center;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: auto;
    -webkit-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
