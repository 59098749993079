.wrapper {
    overflow: auto;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: "Meta", sans-serif;
}
.top {
    font-size: 25px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: auto;
}
.bottom {
    font-size: 12px;
}
.rankWrapper {
    float: right;
    width: 30%;
    text-align: left;
    padding-left: 5px;
}
.rank {
    color: rgb(25, 187, 78);
}
.labelWrapper {
    float: left;
    width: 70%;
    text-align: right;
    padding-right: 5px;
}
.loaderWrapper {
    float: right;
    width: 30%;
    text-align: left;
    padding-left: 5px;
    padding-top: 3px;
}
.loader {
    display: inline-block;
    vertical-align: center;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid rgb(179, 38, 224);
    width: 30px;
    height: 30px;
    -webkit-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
