.tr {
    border: solid 1px;
    height: 100%;
}
.tdLeft {
    border: solid 1px;
    width: 50%;
    padding: 5px;
    vertical-align: top;
}
.tdRight {
    border: solid 1px;
    width: 50%;
    padding: 5px;
    vertical-align: top;
    height: 100%;
}
.input {
    width: 100%;
    border: none;
    resize: none;
    height: 100%;
    margin: 0px;
    padding: 0px;
}
.input:focus {
    outline: none;
}
